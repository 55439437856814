import React, { useState, useEffect } from "react";
import Header from '../../Components/Header'
import FadeInSection from '../../utils/fade_in_section'
import Footer from '../../Components/Footer'
import axios from "axios";


const Gallery = () => {

  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        
        const response = await axios.get(`https://gfgweb-backend.vercel.app/api/image/all`);
        
        if (response.data?.result) {
          setImages(response.data.result);
        } else {
          setError("No images found");
        }

        setLoading(false);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch images. Please try again later.");
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  if (loading) return <div className="text-center text-lg py-4">Loading...</div>;
  if (error) return <div className="text-center text-red-500 py-4">{error}</div>;

  return (
    <>
   <Header />

   <FadeInSection>
       
       
        <div className="flex mt-24 justify-around xs:flex-col lg:flex-row xs:items-center lg:items-start min-h-[75vh]">
          <img
          src={require("../../assets/Winners.gif")}
            className="xs:w-5/6 lg:w-1/3"
            alt="Event"/>

          <div className="xs:w-5/6 lg:w-1/3 flex flex-col items-around gap-y-6 mt-4">
            <div className="font-[700] leading-tight xs:text-center lg:text-right xs:text-4xl lg:text-6xl">
            A Visual Symphony: Dive into the Captivating World of Our Gallery!
            </div>
            <div className="xs:text-center lg:text-right text-base">
              Immerse yourself in a breathtaking collection of moments captured through the lens. From vibrant snapshots of life to awe-inspiring artistic compositions, our gallery celebrates creativity and storytelling in every frame. Discover the beauty, emotion, and inspiration behind each image. Explore our collection and let every picture tell you a story worth remembering.
            </div>
          </div>
        </div>




   




   <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 p-6">
      {images.map((image) => (
        <div
          key={image._id}
          className="group relative bg-gray-200 rounded-lg shadow-md overflow-hidden"
        >
          <img
            src={image.image}
            alt={image.event || "Event image"}
            className="w-full h-88 object-cover transition-transform duration-300 transform group-hover:scale-105"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            <div className="text-white text-center">
              <p className="text-lg font-semibold">{image.event}</p>
              <button className="mt-2 bg-white text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-200 transition-colors"
               onClick={() => setSelectedImage(image.image)}>
                View
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>

     {/* Modal for Full-Screen Image */}
     {selectedImage && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
            <div className="relative">
              <img
                src={selectedImage}
                alt="Full screen view"
                className="max-w-full max-h-screen rounded-lg"
              />
              <button
                className="absolute top-2 right-2 bg-white text-black rounded-full p-2 shadow-lg"
                onClick={() => setSelectedImage(null)} // Close modal
              >
                ✕
              </button>
            </div>
          </div>
        )}







   </FadeInSection>
   <Footer/>
   </>
  )
}

export default Gallery
