import React, { useEffect } from "react";
import "./App.css";
import HomePage from "./screens/HomePage/HomePage";
import LoginPage from "./screens/hackmatrix/loginPage/LoginPage";
import TeamPage from "./screens/TeamPage/TeamPage";
import EventPage from './screens/EventPage/EventPage';
import ContactPage from "./screens/ContactPage/ContactPage";
import AboutPage from "./screens/AboutPage/AboutPage";
import ProblemStatementsPage from "./Components/problemStatements";
import HackathonPage from "./screens/hackmatrix/HackathonPage/hackathon_page";
import HackTeamMembers from "./Components/HackMatrixTeam";
import ContactSupportPage from "./Components/contactSupport";
import DashBoardIndex from './screens/hackmatrix/dashboardPage';
import { ISLOGGED } from './utils/constants';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Finalists from "./Components/Finalists";
import Gallery from "./screens/gallery/Gallery";


const App = () => {
  // useEffect(() => {
  //   const handleContextMenu = (e) => {
  //     e.preventDefault();
  //   };

  //   const handleKeyDown = (e) => {
  //     if ((e.ctrlKey || e.metaKey) && e.shiftKey && (e.key === 'I' || e.key === 'i')) {
  //       e.preventDefault();
  //     }
  //   };

  //   window.addEventListener('contextmenu', handleContextMenu);
  //   window.addEventListener('keydown', handleKeyDown);

  //   return () => {
  //     window.removeEventListener('contextmenu', handleContextMenu);
  //     window.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, []);
  return (
    <>
      <div className="selection:bg-green-600">
        <Router onUpdate={() => window.scrollTo(0, 0)}>
          <Routes>
            <Route path="/" exact element={<HomePage />} />
            <Route path="/team" element={<TeamPage />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/events" element={<EventPage />} /> 
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/hackmatrix" element={<HackathonPage />} />
            <Route path="/ps" element={<ProblemStatementsPage />} />
            <Route path="/login" element={localStorage.getItem(ISLOGGED) ? <Navigate to="/dashboard" /> : <LoginPage />} />
            <Route path="/hackteam" element={<HackTeamMembers/> } />
            <Route path="/contact-support" element={ <ContactSupportPage/> } />
            <Route path="/dashboard" element={ localStorage.getItem(ISLOGGED) ? <DashBoardIndex/> : <Navigate to="/login"/>} />
            <Route path="/hackmatrix/finalists" element={ <Finalists/> } />
          </Routes>
        </Router>
      </div>
    </>
  );
};

export default App;
