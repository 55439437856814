import React, { useState } from "react";
import dateFormat from "dateformat";
import parse from "html-react-parser";
import { ReactComponent as CalendarSVG } from "../../assets/calendar.svg";

const PastEventCard = ({ props }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <div className="flex flex-col w-full shadow-xl overflow-clip rounded-xl my-4">
        <div className="flex xs:flex-col lg:flex-row xs:items-center lg:items-start">
          <img
            className="aspect-square object-cover h-full xs:w-full lg:w-[20%] lg:rounded-l-xl"
            src={props.image}
            alt="Event Photo"
          />

          {/* Text Container */}
          <div className="text-container my-4 lg:mx-8 xs:mt-8 px-4 w-full">
            <div className="text-3xl font-bold"> {props.name} </div>

            <div className="flex flex-row items-start mt-2">
              <CalendarSVG className="w-6 h-6 stroke-gray-500" />
              <div className="text-md text-gray-500 ml-2">
                {dateFormat(props.date, "mmmm dS, yyyy")}
              </div>
            </div>

            <div className="text-md my-8 mr-4">{parse(props.description)}</div>

            {/* know more button */}
            <div className="flex flex-col xs:items-center lg:items-end">
              <button
                onClick={() => setShowModal(true)}
                className="w-content px-12 py-6 bg-gfg-green rounded-xl justify-center shadow-md translate-y-0 ease-in duration-200 hover:-translate-y-1 hover:shadow-2xl cursor-pointer"
              >
                <div className="text-center text-white font-bold">
                  Know More
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto">
          <div 
            className="absolute inset-0 bg-black/50 backdrop-blur-sm"
            onClick={() => setShowModal(false)}
          />
          <div className="relative bg-white rounded-xl p-8 max-w-4xl w-full m-4 max-h-[90vh] overflow-y-auto">
            <button
              onClick={() => setShowModal(false)}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
            >
              <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>

            <div className="flex items-center space-x-4 mb-8">
              <img
                src={props.image || 'https://via.placeholder.com/400x400'}
                alt={props.name}
                className="w-24 h-24 rounded-lg object-cover"
              />
              <div>
                <h2 className="text-3xl font-bold ">{props.name}</h2>
                <p className="text-xl text-gray-600">
                  {props.date ? dateFormat(props.date, "mmmm dS, yyyy") : 'Date TBA'}
                </p>
              </div>
            </div>

            {props.details && (
              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">About the Event</h3>
                <p className="text-gray-700">{props.details}</p>
              </div>
            )}

            {props.schedule && props.schedule.checkpoints?.length > 0 && (
              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-4">Event Schedule</h3>
                <div className="space-y-2">
                  {props.schedule.checkpoints.map((checkpoint, index) => (
                    <div key={index} className="flex items-center space-x-4">
                      <div className="w-fit  text-gray-600">{checkpoint.time}</div>
                      <div className="flex-1">{checkpoint.event}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {props.prizes && (
              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-4">Prizes</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {props.prizes.first && (
                    <div className="bg-green-50 p-4 rounded-lg">
                      <div className="font-semibold">First Prize</div>
                      <div className="text-2xl font-bold text-green-600">{props.prizes.first}</div>
                    </div>
                  )}
                  {props.prizes.second && (
                    <div className="bg-blue-50 p-4 rounded-lg">
                      <div className="font-semibold">Second Prize</div>
                      <div className="text-2xl font-bold text-blue-600">{props.prizes.second}</div>
                    </div>
                  )}
                  {props.prizes.third && (
                    <div className="bg-purple-50 p-4 rounded-lg">
                      <div className="font-semibold">Third Prize</div>
                      <div className="text-2xl font-bold text-purple-600">{props.prizes.third}</div>
                    </div>
                  )}
                  {props.prizes.special && (
                    <div className="bg-orange-50 p-4 rounded-lg">
                      <div className="font-semibold">Special Prize</div>
                      <div className="text-lg font-bold text-orange-600">{props.prizes.special}</div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {props.technical && (
              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-4">Technical Details</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {props.technical.teamSize && (
                    <div className="border p-4 rounded-lg">
                      <div className="font-semibold mb-2">Team Size</div>
                      <div>{props.technical.teamSize}</div>
                    </div>
                  )}
                  {props.technical.stack && (
                    <div className="border p-4 rounded-lg">
                      <div className="font-semibold mb-2">Tech Stack</div>
                      <div>{props.technical.stack}</div>
                    </div>
                  )}
                  {props.technical.submission && (
                    <div className="border p-4 rounded-lg">
                      <div className="font-semibold mb-2">Submission</div>
                      <div>{props.technical.submission}</div>
                    </div>
                  )}
                  {props.technical.resources && (
                    <div className="border p-4 rounded-lg">
                      <div className="font-semibold mb-2">Resources</div>
                      <div>{props.technical.resources}</div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {props.venue && (
              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-4">Venue Details</h3>
                <div className="bg-gray-50 p-6 rounded-lg">
                  {props.venue.address && <p className="font-semibold">{props.venue.address}</p>}
                  {props.venue.facilities && <p className="mt-2">{props.venue.facilities}</p>}
                  {props.venue.accommodation && <p className="mt-2">{props.venue.accommodation}</p>}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PastEventCard;
